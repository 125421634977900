.heroSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
        margin: var(--space-48) auto var(--space-8);
        text-align: center;
    }

    p {
        max-width: 550px;
        margin-bottom: var(--space-32);
    }

    img {
        max-width: 428px;
    }

    @media (--screen-lt-sm) {
        h1 {
            margin-top: var(--space-32);
        }

        p {
            margin-bottom: var(--space-24);
        }

        img {
            max-width: 284px;
        }
    }
}
